<template>
  <div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-10">优化项</h4>
      <Table
        stripe
        size="small"
        :data="optimizeStatistic"
        :columns="optimizeStatisticColumns"
      ></Table>

    </div>
    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-10">作业类型</h4>
      <Table
        size="small"
        stripe
        :data="workTypeData"
        :columns="taskStatisticColumns"
      ></Table>
    </div>

    <div class="m-b-10">
      <h4 class="workplatform-title m-t-10 m-b-10">供应商</h4>
      <Table
        size="small"
        stripe
        :data="supplierStatData"
        :columns="supplierColumns"
      ></Table>

    </div>

  </div>
</template>

<script>
import { sysMixins } from '@/assets/mixins/sys'
// import { gettasktypestatusstatistic } from '@/api/msp/plan'
// import { getWorkPlanUpRate } from '@/api/dw/workplan'
// import { getTaskitemTypeStat, getSupplierStat } from '@/api/msp/workday'

export default {
  mixins: [sysMixins],
  data () {
    return {
      taskStatisticColumns: [
        { title: '任务状态', key: 'taskitemTypeName' },
        {
          title: '已派单/已完成/总计',
          align: 'right',
          key: 'totalQuantity',
          render: (h, params) => {
            return h(
              'span',
              params.row.assignNum + '/' + params.row.finishNum + '/' + params.row.totalTaskitemNum
            )
          }
        }
      ],
      taskStatistic: [],

      optimizeStatisticColumns: [
        { title: '类别', key: 'name' },
        { title: '优化前', key: 'targetAmount' },
        { title: '优化后', key: 'actualAmount' }
      ],
      optimizeStatistic: [],
      supplierArray: [],
      supplierColumns: [
        { title: '供应商', key: 'companyName', ellipsis: true, tooltip: true },
        { title: '负责人', align: 'center', key: 'executeUserName' },
        { title: '作业数', align: 'center', key: 'taskitemCount' }
      ]
    }
  },
  computed: {
    workTypeData () {
      return this.$store.state.board.workTypeData
    },
    supplierStatData () {
      return this.$store.state.board.supplierStatData
    }
  },
  destroyed () {
    this.$store.commit('reset_state')
  },
  methods: {
    // /**
    //  * 获取作业类型统计
    //  */
    // getTaskTypeStatData () {
    //   const query = {
    //     startDate: this.startDate,
    //     endDate: this.endDate,
    //     stationId: this.stationId
    //   }
    //   getTaskitemTypeStat(query).then(res => {
    //     if (res && !res.errcode) {
    //       this.taskStatistic = res
    //     } else {
    //       this.taskStatistic = []
    //     }
    //   })
    // },
    // getSupplierStatData () {
    //   const query = {
    //     startDate: this.startDate,
    //     endDate: this.endDate,
    //     stationId: this.stationId
    //   }
    //   getSupplierStat(query).then(res => {
    //     if (res && !res.errcode) {
    //       this.supplierArray = res
    //     } else {
    //       this.supplierArray = []
    //     }
    //   })
    // }
  },
  watch: {
    // startDate () {
    //   this.getTaskTypeStatData()
    //   this.getSupplierStatData()
    // }
  }
}
</script>
